import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/Header2";
import Footer from "../layout/footer";
import { contact } from "../layout/language";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      alert: "",
    };
  }

  handlePost(e) {
    e.preventDefault();
    if (this.state.name && this.state.email && this.state.message) {
      this.setState({
        alert: [
          "Mesajınız gönderiliyor, lütfen bekleyiniz...",
          "Your message is being sent, please wait...",
        ],
      });
      try {
        const url = "https://api.deepyazilim.com/api/Mail";
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
          }),
        };
        return fetch(url, options).then(() => {
          this.setState({
            alert: [
              "Mesajınız gönderildi, teşekkür ederiz!",
              "Your message was sent, thank you!",
            ],
          });
        });
      } catch (error) {
        return this.setState({
          alert: [
            "Mesajınız gönderilemedi, lütfen tekrar deneyiniz ya da sol tarafta bulunan diğer iletişim yöntemlerini deneyiniz.",
            "Your message could not be sent, please try again or try other contact way in left side ",
          ],
        });
      }
    } else {
      return this.setState({
        alert: [
          "Lütfen gerekli tüm alanları doldurunuz!!!",
          "Please fill all mandatory fields!!!",
        ],
      });
    }
  }

  sendMail({ mailSubject, mailMessage }) {
    return (window.location.href =
      "mailto:" +
      "info@smilodonsoft.com" +
      "?subject=" +
      encodeURIComponent(mailSubject[this.props.language]) +
      "&body=" +
      encodeURIComponent(mailMessage[this.props.language]));
  }

  render() {
    return (
      <>
        <Header
          language={this.props.language}
          setLanguage={(data) => this.props.setLanguage(data)}
        />

        {/* <!--Search Popup--> */}
        {/* <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        {/* <!-- Page Banner Section --> */}
        {/* <section className="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div className="bottom-rotten-curve alternate"></div>

                    <div className="auto-container">
                        <h1>Contact Us</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li className="active">Contact</li>
                        </ul>
                    </div>

                </section> */}

        {/* <!-- Map Section --> */}
        {/* <!-- Map Section --> */}
        <section className="map-section">
          <div
            className="map-column"
            style={{ height: "420px", width: "100%" }}
          >
            {/* <GoogleMaps
                            apiKey={"AIzaSyBQ5y0EF8dE6qwc03FcbXHJfXr4vEa7z54"}
                            style={{ height: "800px", width: "100%" }}
                            zoom={12}
                            center={{ lat: 37.4224764, lng: -122.0842499 }}
                            markers={{ lat: 37.4224764, lng: -122.0842499 }} //optional
                        /> */}
          </div>
        </section>

        {/* <!-- Contact Section Two --> */}
        <section className="contact-section-two">
          <div className="auto-container">
            <div className="contact-info-area">
              <div className="contact-info">
                <div className="row">
                  <div className="info-column col-lg-3">
                    <div
                      className="icon-box"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.sendMail({
                          mailSubject: ["Tanışma", "Meeting"],
                          mailMessage: [
                            "Merhaba Deep Yazilim, \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                            "Hello SmilodonSoft \n \n I want to meet with you. \n Please contact me. \n \n Thanks, \n",
                          ],
                        })
                      }
                    >
                      <div className="icon">
                        <span className="flaticon-email-6"></span>
                      </div>
                      <h3>{contact.c1[this.props.language]}</h3>
                      <ul>
                        <li>
                          <span
                            className="text-dark contactIcon cursorPointer"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.sendMail({
                                mailSubject: ["Tanışma", "Meeting"],
                                mailMessage: [
                                  "Merhaba SmilodonSoft , \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                                  "Hello SmilodonSoft \n \n I want to meet with you. \n Please contact me. \n \n Thanks, \n",
                                ],
                              })
                            }
                          >
                            info@deepyazilim.com
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="info-column col-lg-3">
                    <div className="icon-box">
                      <a
                        className="text-dark contactIcon  "
                        target="_blank"
                        rel="noopener noreferrer"
                        href="tel:+905551631111"
                      >
                        <div className="icon">
                          <span className="flaticon-call"></span>
                        </div>
                        <h3>{contact.c2[this.props.language]}</h3>
                        <ul>
                          <li>+90 555 163 1111</li>
                          <li></li>
                        </ul>
                      </a>
                    </div>
                  </div>
                  <div className="info-column col-lg-3">
                    <div className="icon-box" style={{ cursor: "pointer" }}>
                      <a
                        className="text-dark"
                        href="https://www.google.com.tr/maps/place/Ankara/@39.9035557,32.6226818,11z/data=!3m1!4b1!4m6!3m5!1s0x14d347d520732db1:0xbdc57b0c0842b8d!8m2!3d39.9333635!4d32.8597419!16zL20vMGp5dw?hl=tr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="icon">
                          <span className="flaticon-location"></span>
                        </div>
                        <h3>{contact.c3[this.props.language]}</h3>
                        <ul>
                          <li>
                            <span>
                              {/* <i className="far fa-map-marker-alt" /> */}
                              Ankara
                            </span>
                          </li>
                        </ul>
                      </a>
                    </div>
                  </div>

                  <div className="info-column col-lg-3 ">
                    <div className="icon-box">
                      <a
                        className="text-dark contactIcon "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://wa.me/" +
                          "+905551631111" +
                          "?text=" +
                          "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                        }
                      >
                        <div className="icon">
                          <span className="flaticon-whatsapp"></span>
                        </div>
                        <h3>Whatsapp</h3>
                        <ul>
                          <li>+90 555 163 1111</li>
                        </ul>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-area">
              <div className="sec-title text-center">
                <div className="sub-title">
                  {contact.c4[this.props.language]}
                </div>
                <h2>{contact.c5[this.props.language]}</h2>
              </div>
              {/* <!-- Contact Form--> */}
              <div className="contact-form">
                <form
                  method="post"
                  action="http://azim.commonsupport.com/Finandox/sendemail.php"
                  id="contact-form"
                >
                  <div className="row clearfix">
                    <div className="col-md-6 form-group">
                      <label for="name">
                        {contact.c6[this.props.language]}
                      </label>
                      <input
                        type="text"
                        name="username"
                        id="name"
                        placeholder={contact.c7[this.props.language]}
                        required=""
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                          this.setState({ alert: "" });
                        }}
                      />
                      <i className="fas fa-user"></i>
                    </div>

                    <div className="col-md-6 form-group">
                      <label for="email">
                        {contact.c8[this.props.language]}
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder={contact.c9[this.props.language]}
                        required=""
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                          this.setState({ alert: "" });
                        }}
                      />
                      <i className="fas fa-envelope"></i>
                    </div>

                    <div className="col-md-12 form-group">
                      <label for="message">
                        {contact.c10[this.props.language]}
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        placeholder={contact.c11[this.props.language]}
                        onChange={(e) => {
                          this.setState({ message: e.target.value });
                          this.setState({ alert: "" });
                        }}
                      ></textarea>
                      <i className="fas fa-edit"></i>
                    </div>

                    <div className="col-md-12 form-group">
                      <button
                        className="theme-btn btn-style-one"
                        type="submit"
                        name="submit-form"
                        onClick={(e) => this.handlePost(e)}
                      >
                        <span className="btn-title">
                          {contact.c12[this.props.language]}
                        </span>
                      </button>
                    </div>
                  </div>
                  {this.state.alert && (
                    <div>
                      <div className="form-message-warning mt-4" />
                      <div className="form-message-success text-dark">
                        {this.state.alert[this.props.language]}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer
          language={this.props.language}
          setLanguage={(data) => this.props.setLanguage(data)}
        />
      </>
    );
  }
}
export default Contact;
