import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testimonial1 } from "../layout/language";

const bgimg7 = require("./../../assets/images/background/image-7.jpg");

const Testimonial1_content = [
  {
    thumb: require("./../../assets/images/resource/thumb-1.jpg"),
    author: ["Seyfettin Uslu", "Seyfettin Uslu"],
    designation: ["Kurucu, Must Danışmanlık", "Founder, Seyfettin Uslu"],
    title: ["Kaliteli Hizmet", "Quality Service"],
    description: [
      "Deep Yazılım firmasından sitemiz ile ilgili hizmet aldık.Öncelikle hızlı dönüşleri, anlaşma şartlarını eksiksiz yerine getirmelerinden dolayı tüm emeği geçenlere teşekkür ederiz.",
      "We received service about our site from SmilodonSoft.First of all, we would like to thank all those who contributed to their fast return and fulfilling the terms of the agreement.",
    ],
  },
  {
    thumb: require("./../../assets/images/resource/thumb-2.jpg"),
    author: ["Harun Ateş", "Harun Ates"],
    designation: ["Kurucu, Minel Terapi", "Founder, Minel Therapy"],
    title: ["Güvenilirlik", "Reliability"],
    description: [
      "Bir çok firma ile görüştüm hatta bir kaç kişi ile de anlaşıp başladık. Aldığımız klişe cevaplardan öteye gidemedik.Deep Yazılım ile tanışmamızdan sonra her şey açıklamalı olarak tarafımıza bildirildi ve raporlandı,kısa sürede sonuca vardık.İnanın işi ver arkana yaslan demek en doğrusu olur.",
      "I talked to many companies and even agreed with a few people.We could not go beyond the cliche answers we received.After meeting with SmilodonSoft, everything was explained and reported to us in an explanatory way, and we came to a conclusion in a short time.Believe me, it would be best to say give the job and sit back.",
    ],
  },
  {
    thumb: require("./../../assets/images/resource/thumb-3.jpg"),
    author: ["Aziz Mamedov", "Aziz Mamedov"],
    designation: ["Kurucu, Ahaz Group", "Founder, Ahaz Group"],
    title: ["Kaliteli Hizmet", "Quality Service"],
    description: [
      "Deep Yazılım ekibine içtenlikle teşekkür ederim.İnternet destekleri ve web sayfa yapım sürecinde hiç bir problem yaşamadık.İsteklerimize anında cevap veren,sistemli,düzenli,müşteri odaklı çalışan bir firma.Herkese tavsiye ederim. ",
      "A sincere thank you to the SmilodonSoft team.We had no problems with internet support and period of web page creation.It is a company that responds instantly to our requests, works systematically, regularly and customer-oriented. I recommend it to everyone.",
    ],
  },
  {
    thumb: require("./../../assets/images/resource/thumb-4.jpg"),
    author: ["Mehtap Kalın", "Mehtap Kalin"],
    designation: ["Kurucu, Menadiyet", "Founder, Menadiyet"],
    title: ["Çözüm Odaklı", "Solution Oriented"],
    description: [
      'Hizmet ilk görüşmede başlıyor.Alaka,fikir verme,ufku açma konusunda destek gayet yeterli.Şirket olarak çıkan sonuçtan ve verilen hizmetten ziyadesiyle memnun kaldık."Olmaz" yerine "olurunu bulur ve yaparız" şeklinde yaklaşımları gayet memnun edici.İyi bir iş ortağı olduklarını düşünüyorum. ',
      "The service starts at the first meeting. Support is sufficient in terms of relevance, giving ideas, and opening the horizon.As a company, we were very satisfied with the result and the service provided.Their approach of 'we will find it and we will do it' instead of 'no way' is very satisfying. I think they are good business partners.",
    ],
  },
  // {
  //     thumb: require('./../../assets/images/resource/thumb-5.jpg'),
  //     author: 'Alexer Valvin',
  //     designation: 'Founder, Coxer IT',
  //     title: 'Design Quality',
  //     description:
  //         'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

  // },
  // {
  //     thumb: require('./../../assets/images/resource/thumb-6.jpg'),
  //     author: 'Alexer Valvin',
  //     designation: 'Founder, Coxer IT',
  //     title: 'Design Quality',
  //     description:
  //         'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ',

  // },
];

class Testimonial1 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };
    return (
      <>
        <section
          className="testimonial-section"
          style={{ backgroundImage: "url(" + bgimg7 + ")" }}
        >
          <div className="auto-container">
            <div className="sec-title">
              <div className="sub-title">
                {testimonial1.t1[this.props.language]}
              </div>
              <h2>{testimonial1.t2[this.props.language]} </h2>
            </div>
            <div className="row col-xl-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="col-xl-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <Slider {...settings} className="theme_carousel">
                  {Testimonial1_content.map((item, id) => (
                    <div className="slide-item  " key={id}>
                      {/* <!-- Testimonial-block --> */}
                      <div className="testimonial-block ">
                        <div className="inner-box">
                          <div className="icon">
                            <span className="fal fa-star"></span>
                          </div>
                          <div className="quote">
                            <span className="fal fa-quote-left"></span>
                          </div>
                          <h4 style={{ textAlign: "center" }}>
                            {item.title[this.props.language]}
                          </h4>
                          <div className="text" style={{ textAlign: "center" }}>
                            {item.description[this.props.language]}{" "}
                          </div>
                        </div>
                        <div className="author-info">
                          <div className="thumb">
                            {/* <img src={item.thumb} alt="" /> */}
                          </div>
                          <h4 style={{ textAlign: "center" }}>
                            {item.author[this.props.language]}
                          </h4>
                          <div
                            className="designation"
                            style={{ textAlign: "center" }}
                          >
                            {item.designation[this.props.language]}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default Testimonial1;
