import React, { useState } from "react";
import { Link } from "react-router-dom";
import { services2 } from "../layout/language";

const gallerybg = require("./../../assets/images/background/image-14.jpg");

const images = [
  {
    id: 1,
    name: ["Uygulama", "Application"],
    uygulama: true,
    imgUrl: require("../../assets/images/gallery/1.jpg"),
    title: ["Masaüstü / Mobil", "Desktop / Mobile"],
  },
  {
    id: 2,
    name: ["İnternet Sitesi", "Web Site"],
    web: true,
    imgUrl: require("../../assets/images/gallery/2.jpg"),
    title: ["Şirket / E-ticaret / Ürün", "Company / E-Commerce / Product"],
  },
  {
    id: 3,
    name: ["İnternet Sitesi", "Web Site"],
    web: true,
    imgUrl: require("../../assets/images/gallery/web.jpg"),
    title: ["Şirket / E-ticaret / Ürün", "Company / E-Commerce / Product"],
  },
  //   {
  //     id: 4,
  //     name: ["TradingView", "TradingView"],
  //     tv: true,
  //     imgUrl: require("../../assets/images/gallery/4.jpg"),
  //     title: ["Borsa Grafiği", "Stock Chart"],
  //   },
  //   {
  //     id: 5,
  //     name: ["TradingView", "TradingView"],
  //     tv: true,
  //     imgUrl: require("../../assets/images/gallery/5.jpg"),
  //     title: ["Borsa Grafiği", "Stock Chart"],
  //   },
  {
    id: 6,
    name: ["Alan Adı / Sunucu", "Hosting / Server"],
    host: true,
    imgUrl: require("../../assets/images/gallery/6.jpg"),
    title: [
      "Web Servis Sağlayıcıları / Sunucu / Bulut",
      "Web Service Providers / Server / Cloud",
    ],
  },
  {
    id: 7,
    name: ["Alan Adı / Sunucu", "Hosting / Server"],
    host: true,
    imgUrl: require("../../assets/images/gallery/7.jpg"),
    title: [
      "Web Servis Sağlayıcıları / Sunucu / Bulut",
      "Web Service Providers / Server / Cloud",
    ],
  },
  {
    id: 8,
    name: ["Grafik Tasarımı", "Graphic Design"],
    graphic: true,
    imgUrl: require("../../assets/images/gallery/8.jpg"),
    title: ["İnternet Sitesi / Uygulama", "Web Site / Application"],
  },
  {
    id: 9,
    name: ["Grafik Tasarımı", "Graphic Design"],
    graphic: true,
    imgUrl: require("../../assets/images/gallery/9.jpg"),
    title: ["İnternet Sitesi / Uygulama", "Web Site / Application"],
  },
  {
    id: 10,
    name: ["Uygulama", "Application"],
    uygulama: true,
    imgUrl: require("../../assets/images/gallery/10.jpg"),
    title: ["Masaüstü / Mobil", "Desktop / Mobile"],
  },
  {
    id: 11,
    name: ["Uygulama", "Application"],
    uygulama: true,
    imgUrl: require("../../assets/images/gallery/11.jpg"),
    title: ["Masaüstü / Mobil", "Desktop / Mobile"],
  },
  {
    id: 12,
    name: ["İnternet Sitesi", "Web Site"],
    web: true,
    imgUrl: require("../../assets/images/gallery/12.jpg"),
    title: ["Şirket / E-ticaret / Ürün", "Company / E-Commerce / Product"],
  },
];

const FILTER_DEFS = {
  U: (image) => image.uygulama,
  G: (image) => image.graphic,
  I: (image) => image.web,
  A: (image) => image.host,
  T: (image) => image.tv,
  NONE: (image) => image,
};

const ServiceFilter1 = ({ props, language }) => {
  const [state, setState] = useState({
    list: images,
    filterKey: "NONE",
  });
  const { list, filterKey } = state;
  const filteredList = list.filter(FILTER_DEFS[filterKey]);
  return (
    <>
      <section className="gallery-section">
        <div
          className="sec-bg"
          style={{ backgroundImage: "url(" + gallerybg + ")" }}
        ></div>
        <div className="sortable-masonry">
          <div className="auto-container">
            <div className="sec-title light text-center">
              <div className="sub-title">{services2.s21[language]}</div>
              <h2>
                {services2.s22[language]} <br />
                {services2.s23[language]}
              </h2>
            </div>
            {/* <!--Filter--> */}
            <div className="filters text-center light">
              <ul className="filter-tabs filter-btns">
                <li
                  className="active filter"
                  onClick={() => setState({ ...state, filterKey: "NONE" })}
                >
                  <span>{services2.s24[language]}</span>
                </li>
                <li
                  className="filter"
                  onClick={() => setState({ ...state, filterKey: "U" })}
                >
                  <span>{services2.s1[language]}</span>
                </li>
                <li
                  className="filter"
                  onClick={() => setState({ ...state, filterKey: "G" })}
                >
                  <span>{services2.s2[language]}</span>
                </li>
                <li
                  className="filter"
                  onClick={() => setState({ ...state, filterKey: "I" })}
                >
                  <span>{services2.s3[language]} </span>
                </li>
                <li
                  className="filter"
                  onClick={() => setState({ ...state, filterKey: "A" })}
                >
                  <span>{services2.s4[language]}</span>
                </li>
                {/* <li className="filter" onClick={() => setState({ ...state, filterKey: "T" })}><span>TradingView</span></li> */}
              </ul>
            </div>
          </div>
          <div className="auto-container">
            <div className="items-container row">
              {/* <!-- Gallery Block One --> */}
              {filteredList.map((image) => (
                <div
                  className="col-lg-4 col-md-6 gallery-block-one"
                  key={image.id}
                >
                  <div className="inner-box">
                    <div className="image">
                      <img src={image.imgUrl} alt="" />
                    </div>
                    <div className="caption-title">
                      <h5>{image.name[language]}</h5>
                      <h3>
                        <Link to={"/#"}>{image.title[language]}</Link>
                      </h3>
                      {/* <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-10.jpg" className="zoom-btn"><span>+</span></Link></div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceFilter1;
